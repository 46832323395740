import { Component, Mixins } from 'vue-property-decorator';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import template from './GestionNumeroFournisseursVesta.Template.vue';
import { NumeroFournisseurVesta } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { PagingAndSorting } from '@/shared/models';
import { SocieteCriteria } from './SocieteCriteria.model';

@Component({
    ...template,
    name: 'GestionNumeroFournisseursVesta',
})
export default class GestionNumeroFournisseursVesta extends Mixins(GrilleMixin) {
    private headers: any[];
    private numeroFournisseurVesta: NumeroFournisseurVesta[] = new Array<NumeroFournisseurVesta>();
    private loading: boolean;
    public totalNumeroFournisseurVesta: number;
    private numeroFournisseurVestaGet = new ApiService<NumeroFournisseurVesta>('societe/RecupererListeNumeroFournisseurVesta');
    private numeroFournisseurVestaPost = new ApiService<NumeroFournisseurVesta>('societe/ModifierNumeroFournisseurVesta');
    private societeCriteria: SocieteCriteria;

    constructor() {
        super();
        this.headers = [];
        this.loading = false;
        this.societeCriteria = new SocieteCriteria();
        this.totalNumeroFournisseurVesta = 0;
    }
    public created() {
        this.headers = [
            { text: 'Raison sociale', value: 'raisonSociale', class: 'text-xs-center' },
            { text: 'Siret', value: 'siret', class: 'text-xs-center' },
            { text: 'Numéro fournisseur Vesta', value: 'numeroFournisseurVesta', class: 'text-xs-center' },
        ];
        this.getData();
    }

    public getData() {
        this.societeCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getAllNumeroFournisseurVesta(this.societeCriteria);
    }

    public getAllNumeroFournisseurVesta(criteresRecherche: SocieteCriteria): Promise<void> {
        return new Promise((resolve) => {
            this.loading = true;
            this.numeroFournisseurVestaGet.getWhere(criteresRecherche.queryString).then((value) => {
                this.numeroFournisseurVesta = value.data.data;
                this.totalNumeroFournisseurVesta = value.data.totalCount;
                resolve();
            }).finally(() => { this.loading = false; });
        });
    }

    public modifierNumeroFournisseurVesta(model: NumeroFournisseurVesta) {
        if (!this.loading) {
            this.numeroFournisseurVestaPost.post(model).finally(() => this.getData());
        }
    }
}
